<template>
    <div class="bg-white rounded-lg px-[8%]">
        <div class="mb-5 p-5">
            <h2 class="font-medium md:text-2xl text-base md:mb-10 mb-3">角色資訊</h2>
            <el-form ref="form" class="relative" :model="form" :rules="rules">
                <el-form-item class="w-full" prop="name">
                    <label class="block">角色名稱</label>
                    <el-input v-model="form.name" class="w-full max-w-[250px]" placeholder="請輸入角色名稱"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="border-b-2 border-gray-100"></div>
        <div class="p-5">
            <h2 class="font-medium md:text-2xl text-base mr-5">角色權限</h2>
            <div class="border border-gray-100 rounded-lg mt-5">
                <div class="py-2 border-b border-gray-100">
                    <ul class="flex py-2 justify-start">
                        <li
                            v-for="(item, index) in titles"
                            v-show="index !== 2"
                            :key="index"
                            :class="[index === 0 ? 'flex-1 ml-[32px] max-w-[290px] min-w-[290px]' : '', index === 1 ? 'flex-1' : '']">
                            {{ item }}
                        </li>
                        <li v-for="method in titles[2]" :key="method" class="min-w-[80px] text-center">{{ method }} <br /></li>
                    </ul>
                </div>
                <el-tree
                    :data="permissionList"
                    node-key="id"
                    :expand-on-click-node="false"
                    default-expand-all
                    :icon-class="'el-icon-arrow-right text-xl text-black'">
                    <div slot-scope="{ data }" class="flex items-center justify-start w-full">
                        <div :class="data.parent_id !== null ? 'max-w-[268px] min-w-[268px]' : 'max-w-[300px] min-w-[300px]'">
                            <h5 :class="[data.is_menu ? 'text-red-600' : 'text-black']">
                                {{ data.name }}
                                <span v-if="data.is_menu" class="text-red-600">(左側選單) </span>
                                <span v-if="data.is_child_feature" class="text-blue-600">(子功能) </span>
                            </h5>
                        </div>
                        <!-- {{ data.child_features }} {{ data.key }} -->
                        <div :class="'flex-1'">
                            <ul v-if="!$isEmpty(data.child_features)" class="flex flex-wrap">
                                <li v-for="(item, index) in data.child_features" :key="index" :class="index !== 0 ? 'ml-2' : ''">
                                    <label v-if="selectedChildFeature[data.key] !== undefined" :for="item.key">
                                        <input :id="item.key" v-model="selectedChildFeature[data.key][item.key]" type="checkbox" />
                                        <!-- {{ selectedChildFeature[data.key] }} -->
                                        {{ item.name }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="flex">
                            <div class="min-w-[80px] text-center">
                                <input
                                    v-model="checkedRead[data.key]"
                                    :disabled="
                                        checkReadDisabled[data.key] || checkedUpdate[data.key] || checkedCreate[data.key] || checkedDelete[data.key]
                                    "
                                    class=""
                                    type="checkbox"
                                    @change="checkReadSelect(data.key, data.parent_id)" />
                            </div>
                            <div class="min-w-[80px] text-center">
                                <input v-model="checkedCreate[data.key]" class="" type="checkbox" @change="checkSelect(data.key, data.parent_id)" />
                            </div>
                            <div class="min-w-[80px] text-center">
                                <input v-model="checkedUpdate[data.key]" class="" type="checkbox" @change="checkSelect(data.key, data.parent_id)" />
                            </div>
                            <div class="min-w-[80px] text-center">
                                <input v-model="checkedDelete[data.key]" class="" type="checkbox" @change="checkSelect(data.key, data.parent_id)" />
                            </div>
                        </div>
                    </div>
                </el-tree>
            </div>
            <div class="flex justify-center my-5">
                <btn
                    v-if="!isUpdate"
                    :disabled="loading"
                    :color="loading ? 'disabled:text-white' : 'text-black border border-black mr-5'"
                    @onClick="$router.push({ name: 'permission_roles' })">
                    取消
                </btn>
                <btn
                    v-if="isUpdate"
                    v-permission="['delete']"
                    :disabled="loading"
                    :color="loading ? 'disabled:text-white' : 'text-black border border-black mr-5'"
                    @onClick="openDialog">
                    刪除角色
                </btn>
                <btn v-if="isUpdate" v-permission="['update']" v-loading="loading" :disabled="loading" @onClick="onSubmit('form')">儲存變更</btn>
                <btn v-else v-permission="['create']" v-loading="loading" :disabled="loading" @onClick="onSubmit('form')">儲存變更</btn>
            </div>
        </div>
        <!-- 確認是否刪除路由彈窗 -->
        <my-dialog :showDialog="showDialog" :customClass="'top-1/4 z-[70]'" :customBlackBgClass="'z-[60]'" @onCloseDialog="closeDialog">
            <div class="w-full md:p-10 p-5 bg-white rounded-lg text-sm">
                <p>
                    你確認刪除 <span class="text-red-600">{{ form.name }}</span> 角色嗎？
                </p>
                <div class="flex justify-center mt-5">
                    <button
                        :disabled="loading"
                        class="border border-black px-3 py-0.5 rounded mr-5 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-white disabled:border-gray-300"
                        @click.prevent="closeDialog">
                        關閉
                    </button>
                    <button
                        v-loading="loading"
                        :disabled="loading"
                        class="bg-orange-600 px-3 py-0.5 rounded text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                        @click.prevent="deleteData()">
                        確認
                    </button>
                </div>
            </div>
        </my-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";

// 導入自定義彈窗組件
import myDialog from "@/components/Dialog.vue";
import { flattenDeep } from "lodash/array";
export default {
    name: "PermissionRoleForm",
    components: {
        Btn,
        myDialog,
    },
    filters: {
        showKey(val) {
            switch (val) {
                case "路由名稱":
                    return "name";
                case "子項功能":
                    return "child_features";
                case "讀取":
                    return "per_read";
                case "新增":
                    return "per_create";
                case "編輯":
                    return "per_update";
                case "刪除":
                    return "per_delete";

                default:
                    return "";
            }
        },
    },
    data() {
        return {
            id: 0,
            parentSelect: {},
            form: {},
            rules: {
                name: [{ required: true, message: "角色名稱為必填", trigger: "blur" }],
            },
            // 標題
            titles: ["路由名稱", "子項功能", ["讀取", "新增", "編輯", "刪除"]],
            permissionList: [
                // {
                //     id: 1,
                //     key: "permission_control",
                //     name: "權限管理",
                //     is_menu: 1,
                //     is_child_feature: 0,
                //     child_features: null,
                //     created_at: "2022-01-16T13:17:52.000000Z",
                //     updated_at: "2022-01-16T13:17:52.000000Z",
                //     parent_id: null,
                //     children: [
                //         {
                //             id: 2,
                //             key: "permission_routes",
                //             name: "路由權限",
                //             is_menu: 0,
                //             is_child_feature: 0,
                //             child_features: [
                //                 {
                //                     key: "permission_routes_1",
                //                     name: "不顯示路由名稱"
                //                 }
                //             ],
                //             created_at: "2022-01-16T13:17:52.000000Z",
                //             updated_at: "2022-01-16T13:17:52.000000Z",
                //             parent_id: 1,
                //             children: []
                //         },
                //         {
                //             id: 3,
                //             key: "permission_roles",
                //             custom_key: "",
                //             name: "角色列表",
                //             is_menu: 0,
                //             is_child_feature: 0,
                //             child_features: null,
                //             route_set: null,
                //             created_at: "2022-01-16T13:17:52.000000Z",
                //             updated_at: "2022-01-16T13:17:52.000000Z",
                //             parent_id: 1,
                //             children: []
                //         },
                //         {
                //             id: 4,
                //             key: "permission_role_set",
                //             name: "角色權限設定",
                //             is_menu: 0,
                //             is_child_feature: 0,
                //             child_features: null,
                //             created_at: "2022-01-16T13:17:52.000000Z",
                //             updated_at: "2022-01-16T13:17:52.000000Z",
                //             parent_id: 1,
                //             children: []
                //         }
                //     ]
                // },
                // {
                //     id: 5,
                //     key: "user_control",
                //     name: "用戶管理",
                //     is_menu: 0,
                //     is_child_feature: 0,
                //     child_features: null,
                //     route_set: null,
                //     created_at: "2022-01-16T13:17:52.000000Z",
                //     updated_at: "2022-01-16T13:17:52.000000Z",
                //     parent_id: null,
                //     children: [
                //         {
                //             id: 6,
                //             key: "member_list",
                //             name: "會員管理",
                //             is_menu: 1,
                //             is_child_feature: 0,
                //             child_features: null,
                //             created_at: "2022-01-16T13:17:52.000000Z",
                //             updated_at: "2022-01-16T13:17:52.000000Z",
                //             parent_id: 5,
                //             children: []
                //         },
                //         {
                //             id: 7,
                //             key: "provider_list",
                //             name: "服務商管理",
                //             is_menu: 1,
                //             is_child_feature: 0,
                //             child_features: null,
                //             created_at: "2022-01-16T13:17:52.000000Z",
                //             updated_at: "2022-01-16T13:17:52.000000Z",
                //             parent_id: 5,
                //             children: []
                //         },
                //         {
                //             id: 8,
                //             key: "provider_add",
                //             name: "新增服務商",
                //             is_menu: 0,
                //             is_child_feature: 0,
                //             child_features: null,
                //             created_at: "2022-01-16T13:17:52.000000Z",
                //             updated_at: "2022-01-16T13:17:52.000000Z",
                //             parent_id: 5,
                //             children: []
                //         },
                //         {
                //             id: 9,
                //             key: "provider_update",
                //             name: "服務商個人資料",
                //             is_menu: 0,
                //             is_child_feature: 0,
                //             child_features: [
                //                 {
                //                     key: "provider_service_set",
                //                     name: "服務設定"
                //                 },
                //                 {
                //                     key: "provider_profile_update",
                //                     name: "服務商個人資料"
                //                 },
                //                 {
                //                     key: "provider_advanced_set",
                //                     name: "進階設定"
                //                 },
                //                 {
                //                     key: "provider_aboutme_update",
                //                     name: "關於我"
                //                 },
                //                 {
                //                     key: "provider_salary",
                //                     name: "撥款帳戶設定"
                //                 }
                //             ],
                //             created_at: "2022-01-16T13:17:52.000000Z",
                //             updated_at: "2022-01-16T13:17:52.000000Z",
                //             parent_id: 5,
                //             children: []
                //         },
                //         {
                //             id: 10,
                //             key: "provider_service_set",
                //             name: "服務設定",
                //             is_menu: 0,
                //             is_child_feature: 1,
                //             child_features: null,
                //             created_at: "2022-01-16T13:17:52.000000Z",
                //             updated_at: "2022-01-16T13:17:52.000000Z",
                //             parent_id: 5,
                //             children: []
                //         },
                //         {
                //             id: 11,
                //             key: "provider_advanced_set",
                //             name: "進階設定",
                //             is_menu: 0,
                //             is_child_feature: 1,
                //             child_features: null,
                //             created_at: "2022-01-16T13:17:52.000000Z",
                //             updated_at: "2022-01-16T13:17:52.000000Z",
                //             parent_id: 5,
                //             children: []
                //         },
                //         {
                //             id: 12,
                //             key: "provider_profile_update",
                //             name: "服務商個人資料更新",
                //             is_menu: 0,
                //             is_child_feature: 1,
                //             child_features: [
                //                 { key: "provider_profile_update_hide_account", name: "隱藏服務商帳號" },
                //                 { key: "provider_profile_update_hide_id", name: "隱藏服務商證件資料" }
                //             ],
                //             created_at: "2022-01-16T13:17:52.000000Z",
                //             updated_at: "2022-01-16T13:17:52.000000Z",
                //             parent_id: 5,
                //             children: []
                //         },
                //         {
                //             id: 13,
                //             key: "provider_aboutme_update",
                //             name: "關於我更新",
                //             is_menu: 0,
                //             is_child_feature: 1,
                //             child_features: null,
                //             created_at: "2022-01-16T13:17:52.000000Z",
                //             updated_at: "2022-01-16T13:17:52.000000Z",
                //             parent_id: 5,
                //             children: []
                //         },
                //         {
                //             id: 14,
                //             key: "provider_salary",
                //             name: "撥款帳戶設定",
                //             is_menu: 0,
                //             is_child_feature: 1,
                //             child_features: null,
                //             created_at: "2022-01-16T13:17:52.000000Z",
                //             updated_at: "2022-01-16T13:17:52.000000Z",
                //             parent_id: 5,
                //             children: []
                //         }
                //     ]
                // }
            ],
            selectedPermissions: {
                // 1: {
                //     key: "permission_control",
                //     create: null,
                //     read: null,
                //     update: null,
                //     delete: null,
                //     parent_id: null,
                //     options: {}
                // },
                // 2: {
                //     key: "permission_routes",
                //     create: null,
                //     read: true,
                //     update: true,
                //     delete: null,
                //     parent_id: 1,
                //     options: {}
                // },
                // 3: {
                //     key: "permission_roles",
                //     create: null,
                //     read: null,
                //     update: null,
                //     delete: null,
                //     parent_id: 1,
                //     options: {}
                // },
                // 4: {
                //     key: "permission_role_set",
                //     create: null,
                //     read: null,
                //     update: null,
                //     delete: null,
                //     parent_id: 1,
                //     options: {}
                // },
                // 5: {
                //     key: "user_control",
                //     create: null,
                //     read: null,
                //     update: null,
                //     delete: null,
                //     parent_id: null,
                //     options: {}
                // },
                // 6: {
                //     key: "member_list",
                //     create: null,
                //     read: null,
                //     update: null,
                //     delete: null,
                //     parent_id: 5,
                //     options: {}
                // },
                // 7: {
                //     key: "provider_list",
                //     create: null,
                //     read: null,
                //     update: null,
                //     delete: null,
                //     parent_id: 5,
                //     options: {}
                // },
                // 8: {
                //     key: "provider_add",
                //     create: null,
                //     read: null,
                //     update: null,
                //     delete: null,
                //     parent_id: 5,
                //     options: {}
                // },
                // 9: {
                //     key: "provider_update",
                //     create: null,
                //     read: true,
                //     update: null,
                //     delete: null,
                //     parent_id: 5,
                //     options: {
                //         provider_advanced_set: true,
                //         provider_aboutme_update: true
                //     }
                // },
                // 10: {
                //     key: "provider_service_set",
                //     create: null,
                //     read: null,
                //     update: null,
                //     delete: null,
                //     parent_id: 5,
                //     options: {}
                // },
                // 11: {
                //     key: "provider_advanced_set",
                //     create: null,
                //     read: null,
                //     update: null,
                //     delete: null,
                //     parent_id: 5,
                //     options: {}
                // },
                // 12: {
                //     key: "provider_profile_update",
                //     create: null,
                //     read: null,
                //     update: null,
                //     delete: null,
                //     parent_id: 5,
                //     options: {}
                // },
                // 13: {
                //     key: "provider_aboutme_update",
                //     create: null,
                //     read: null,
                //     update: null,
                //     delete: null,
                //     parent_id: 5,
                //     options: {}
                // },
                // 14: {
                //     key: "provider_salary",
                //     create: null,
                //     read: null,
                //     update: null,
                //     delete: null,
                //     parent_id: 5,
                //     options: {}
                // }
            },
            // 選中的子項功能
            selectedChildFeature: {},
            // 判斷預設權限 新增權限是否選取
            checkedCreate: { system: true },
            // 判斷預設權限 讀取權限是否選取
            checkedRead: {},
            // 判斷預設權限 更新權限是否選取
            checkedUpdate: {},
            // 判斷預設權限 刪除權限是否選取
            checkedDelete: {},
            // 判斷預設權限 新增功能是否 disabled
            checkCreateDisabled: {},
            // 判斷預設權限 讀取功能是否 disabled
            checkReadDisabled: {},
            // 判斷預設權限 更新功能是否 disabled
            checkUpdateDisabled: {},
            // 判斷預設權限 刪除功能是否 disabled
            checkDeleteDisabled: {},
            // 顯示刪除彈窗
            showDialog: false,
            // 判斷是否為更新
            isUpdate: false,
            loading: false,
            // 發送資料
            sendData: [],
            childrenKeys: [],
        };
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                if (this.isUpdate) {
                    await this.updateRoleName();
                } else {
                    await this.createRoleName();
                }
                await this.updatePermission();
                this.$message({
                    type: "success",
                    message: this.isUpdate ? "更新成功" : "新增成功",
                });
                if (!this.isUpdate) {
                    this.$router.push({ name: "permission_roles" });
                }
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "表單 驗證失敗",
                });
            }
        },
        // 設定發送資料
        setSendData() {
            return new Promise((resolve) => {
                Object.keys(this.selectedPermissions).forEach((objKey) => {
                    const key = this.selectedPermissions[objKey].key;
                    let childFeatures = [];
                    // 判斷是否有選擇 子功能
                    if (this.selectedChildFeature[key] !== undefined) {
                        // 取出子功能的key值加入陣列
                        childFeatures = Object.keys(this.selectedChildFeature[key])
                            .map((childKey) => (this.selectedChildFeature[key][childKey] === true ? childKey : null))
                            .filter((item) => item !== null);
                    }
                    // 發送值整理
                    this.sendData.push({
                        permission_id: objKey,
                        create: this.checkedCreate[key] ? 1 : 0,
                        read: this.checkedRead[key] ? 1 : 0,
                        update: this.checkedUpdate[key] ? 1 : 0,
                        delete: this.checkedDelete[key] ? 1 : 0,
                        child_features: childFeatures.length > 0 ? childFeatures : [],
                    });
                });
                resolve(true);
            });
        },
        /**
         * 新增角色
         */
        async createRoleName() {
            try {
                const { data } = await this.$api.CreateRoleApi(this.form);
                // 設定角色 id
                this.id = data.role.id;
            } catch (err) {
                this.errorCallback({ err });
            }
        },
        // 更新角色
        async updateRoleName() {
            try {
                await this.$api.UpdateRoleApi(this.form.id, this.form);
            } catch (err) {
                this.errorCallback({ err });
            }
        },
        /**
         * 更新角色權限
         */
        async updatePermission() {
            await this.setSendData();
            this.loading = true;
            try {
                await this.$api.SetPermissionApi(this.id, this.sendData);
                this.loading = false;
            } catch (err) {
                // 更新失敗時要清空發送值
                this.sendData = [];
                this.loading = false;
                this.errorCallback({ err });
            }
        },
        /**
         * 刪除角色
         */
        async deleteData() {
            this.loading = true;
            try {
                await this.$api.DeleteRoleApi(this.id);
                this.$message({
                    type: "success",
                    message: "刪除角色成功",
                });
                this.closeDialog();
                this.$router.push({ name: "permission_roles" });
                this.loading = false;
            } catch (err) {
                this.loading = false;
                this.errorCallback({ err });
            }
        },
        //
        /**
         * 設定起始資料
         * @param { type Array(陣列) } datas 路由權限列表
         */
        async setDefault(datas) {
            await this.setChildFeatures(datas);

            await this.initSelectdPermission(datas);
        },
        // 設定子項功能資料
        setChildFeatures(datas) {
            return new Promise((resolve) => {
                datas.forEach((item, index) => {
                    // 判斷是否有子功能
                    if (!this.$isEmpty(item.child_features)) {
                        // 將目前路由表的 key 新增至 selectedChildFeature 變數
                        this.$set(this.selectedChildFeature, item.key, {});
                        // 迴圈新增 子功能到 selectedChildFeature 指定路由表 key 中
                        item.child_features.forEach((childFeature) => {
                            this.$set(this.selectedChildFeature[item.key], childFeature.key, false);
                        });
                    }
                    // 判斷有子路由時才執行
                    if (!this.$isEmpty(item.children)) {
                        if (item.children.length > 0) {
                            this.setDefault(datas[index].children);
                        }
                    }
                });
                resolve(true);
            });
        },
        /**
         * 設定二進位權限 crud 勾選值
         * @param { type Number(數字) } value 二進位權限值
         * @param { type String(字串) } key 對應權限 key
         */
        computedOperation(value, key) {
            /**
             * 二進位權限機制算法
             * (value & 1) === 1 為擁有讀取權限
             * (value & 2) === 2 為擁有新增權限
             * (value & 4) === 4 為擁有編輯權限
             * (value & 8) === 8 為擁有刪除權限
             */
            // 勾選讀取功能
            if ((value & 1) === 1) {
                this.$set(this.checkedRead, key, true);
            }
            // 勾選新增功能
            if ((value & 2) === 2) {
                this.$set(this.checkedCreate, key, true);
            }
            // 勾選更新功能
            if ((value & 4) === 4) {
                this.$set(this.checkedUpdate, key, true);
            }
            // 勾選刪除功能
            if ((value & 8) === 8) {
                this.$set(this.checkedDelete, key, true);
            }
        },
        /**
         * 設定選中的權限值
         * @param { type Array(陣列) } permissions 選中權限資料
         */
        setSelectdPermissions(permissions) {
            permissions.forEach((item) => {
                // 判斷有選中子功能時才執行
                if (JSON.parse(item.pivot.child_features).length > 0) {
                    // 將字串解析成陣列格式
                    const childFeatures = JSON.parse(item.pivot.child_features);
                    childFeatures.forEach((childFeature) => {
                        // 設定選中的子功能
                        this.$set(this.selectedChildFeature[item.key], childFeature, true);
                    });
                }
                // 判斷有選擇 crud 值時 item.pivot.operation 會大於 0 才往下執行
                if (item.pivot.operation > 0) {
                    // 設定 crud 要勾選的選項
                    this.computedOperation(item.pivot.operation, item.key);
                }
            });
        },
        /**
         * 預設初始化可選擇功能
         * @param { type Array(陣列) } permissionList 權限列表
         */
        initSelectdPermission(permissionLists) {
            return new Promise((resolve) => {
                // 沒有權限資料時不往下執行
                if (this.$isEmpty(permissionLists)) {
                    return;
                }
                permissionLists.forEach((item) => {
                    // 設定 permission id 為 key
                    this.$set(this.selectedPermissions, item.id, {});
                    // 初始化可選擇權限資料
                    this.selectedPermissions[item.id] = {
                        key: item.key,
                        options: {},
                        create: null,
                        update: null,
                        read: null,
                        delete: null,
                        parent_id: item.parent_id ?? null,
                    };
                    // 判斷有子路由時 跑遞迴
                    if (!this.$isEmpty(item.children)) {
                        this.initSelectdPermission(item.children);
                    }
                });
                resolve(true);
            });
        },
        /**
         * 設定預設 disabled 讀取功能
         * @param { type Array(陣列) } permissionList 權限列表
         */
        setReadDisabled(permissionLists) {
            permissionLists.forEach((item) => {
                this.checkReadSelect(item.key, item.parent_id ?? null);
                this.checkSelect(item.key, item.parent_id ?? null);
                if (!this.$isEmpty(item.children)) {
                    this.setReadDisabled(item.children);
                }
            });
        },
        // 取得權限清單
        async getPermissionList() {
            try {
                const { data } = await this.$api.GetPermissionsApi();
                this.permissionList = data.permissions;
            } catch (err) {
                this.errorCallback({ err });
            }
        },
        /**
         * 取得目前角色資料
         * @param { type String or Number(字串或數字) } id 角色id
         */
        async getRoleUserData(id) {
            try {
                const { data } = await this.$api.GetSingleRoleApi(id);
                this.$set(this.form, "name", data.name);
                this.$set(this.form, "id", data.id);
                this.setSelectdPermissions(data.permissions);
            } catch (err) {
                console.log(err);
                this.errorCallback({ err });
            }
        },
        // 開啟彈窗
        openDialog() {
            this.showDialog = true;
        },
        // 關閉彈窗
        closeDialog() {
            this.showDialog = false;
        },
        /**
         * 計算被 disabled 的子路由總數 大於 0 時回傳 true
         * 用來判斷是否要解除 主路由的 讀取方法 disabled
         * @param { type Array(陣列) } disabledKeys 目前被 disabled的 key
         * @param { type Array(陣列) } childrenKeys 目前的子路由 key
         */
        countDisabledChildrenKeys(disabledKeys, childrenKeys) {
            let count = 0;
            if (childrenKeys === undefined) {
                return count > 0 ? true : false;
            }
            // 判斷是否有被 disabled 的子路由
            childrenKeys.forEach((key) => {
                // 當有被 disabled的子路由時 需加 1
                if (disabledKeys.includes(key)) {
                    count += 1;
                }
            });
            // 如果有責回傳 true 沒有回傳 false
            return count > 0 ? true : false;
        },
        /**
         * 計算被 disabled 的子路由總數 大於 0 時回傳 true
         * 用來判斷是否要解除 主路由的 讀取方法 disabled
         * @param { type Array(陣列) } disabledKeys 目前被 disabled的 key
         * @param { type Array(陣列) } childrenKeys 目前的子路由 key
         */
        countReadSelectedKeysByTrue(readObj, childrenKeys) {
            let count = 0;
            // 判斷是否有被 disabled 的子路由
            Object.keys(readObj).forEach((objKey) => {
                if (childrenKeys.includes(objKey) && readObj[objKey] === true) {
                    console.log(childrenKeys.includes(objKey), objKey, readObj[objKey] === true, "objKey");
                    count += 1;
                }
            });
            // 如果有則回傳 true 沒有回傳 false
            return count > 0 ? true : false;
        },
        /**
         * 判斷是否選中子路由 讀取 功能 如選中需要將 上層路由 讀取打勾
         * @param { type String(字串) } key 選種的當前路由 key
         * @param { type String or Number(字串或數字) } parentId 上層路由 id
         */
        checkReadSelect(key, parentId) {
            // 取得上層路由 key
            let parentKey = null;
            if (parentId === null) {
                // 判斷 parentId 為 null 時代表為主路由 因此 parentKey 等於自己的 key
                parentKey = key;
            } else {
                // 判斷 parentId 非 null 時代表為子路由 因此 parentKey 主路由 key
                parentKey = this.selectedPermissions[parentId].key;
            }

            const childrens = this.permissionList.filter((item) => item.id === parentId).map((item) => item.children);
            const childrenKeys = childrens.map((item) => {
                const keys = [];
                item.forEach((children) => {
                    keys.push(children.key);
                });

                return keys;
            });
            this.childrenKeys[parentKey] = flattenDeep(childrenKeys);
            // 判斷上層路由 讀取功能未選取時 自動選取讀取功能
            if (
                (!this.checkedRead[parentKey] && parentId !== null) ||
                (this.countReadSelectedKeysByTrue(this.checkedRead, this.childrenKeys[parentKey]) && parentId !== null)
            ) {
                // 選取主路由的 讀取選項
                this.$set(this.checkedRead, parentKey, true);
                // disabeld 主路由的 讀取選項
                this.$set(this.checkReadDisabled, parentKey, true);
            }
            if (
                !this.countReadSelectedKeysByTrue(this.checkedRead, this.childrenKeys[parentKey]) &&
                parentId !== null &&
                this.checkReadDisabled[parentKey]
            ) {
                this.$set(this.checkReadDisabled, parentKey, false);
            }
        },
        /**
         * 取得被 disabled 的 key
         * @param { type Object(物件) } obj read disabled 物件值 只過濾出 被 disabled 的選項key
         */
        getReadDisabledKeys(obj) {
            return Object.keys(obj)
                .map((objKey) => {
                    if (this.checkReadDisabled[objKey] === true) {
                        return objKey;
                    }
                })
                .filter((item) => item !== undefined);
        },
        /**
         * 判斷是否選中 新增｜更新｜刪除 功能 如選中需要將 上層路由 以及 當前路由 讀取打勾
         * @param { type String(字串) } key 選種的當前路由 key
         * @param { type String or Number(字串或數字) } parentId 上層路由 id
         */
        checkSelect(key, parentId) {
            let parentKey = null;
            if (parentId === null) {
                // 判斷 parentId 為 null 時代表為主路由 因此 parentKey 等於自己的 key
                parentKey = key;
            } else {
                // 判斷 parentId 非 null 時代表為子路由 因此 parentKey 主路由 key
                parentKey = this.selectedPermissions[parentId].key;
            }
            let disabledKeys = this.getReadDisabledKeys(this.checkReadDisabled);
            if (!this.checkedCreate[key] && !this.checkedUpdate[key] && !this.checkedDelete[key]) {
                if (!this.countDisabledChildrenKeys(disabledKeys, this.childrenKeys[parentKey]) && parentId === null) {
                    this.$set(this.checkReadDisabled, key, false);
                    disabledKeys = this.getReadDisabledKeys(this.checkReadDisabled);
                }
                if (!this.countDisabledChildrenKeys(disabledKeys, this.childrenKeys[parentKey]) && parentId !== null) {
                    this.$set(this.checkReadDisabled, key, false);
                    disabledKeys = this.getReadDisabledKeys(this.checkReadDisabled);
                }
            } else {
                this.$set(this.checkReadDisabled, key, true);
            }
            if (this.childrenKeys[parentKey] !== undefined) {
                if (!this.countDisabledChildrenKeys(disabledKeys, this.childrenKeys[parentKey]) && parentId !== null) {
                    this.$set(this.checkReadDisabled, parentKey, false);
                    disabledKeys = this.getReadDisabledKeys(this.checkReadDisabled);
                }
            }
            // 判斷當前路由 讀取功能未選取時 自動選取讀取功能
            if (!this.checkedRead[key]) {
                this.$set(this.checkedRead, key, true);
                this.$set(this.checkReadDisabled, key, true);
                disabledKeys = this.getReadDisabledKeys(this.checkReadDisabled);
            }
            if (parentId === null) {
                return;
            }

            // 取得上層路由 key
            parentKey = this.selectedPermissions[parentId].key;

            const childrens = this.permissionList.filter((item) => item.id === parentId).map((item) => item.children);
            const childrenKeys = childrens.map((item) => {
                const keys = [];
                item.forEach((children) => {
                    keys.push(children.key);
                });

                return keys;
            });
            this.childrenKeys[parentKey] = flattenDeep(childrenKeys);

            if (!this.checkedCreate[key] && !this.checkedUpdate[key] && !this.checkedDelete[key]) {
                this.$set(this.checkReadDisabled, key, false);
                disabledKeys = this.getReadDisabledKeys(this.checkReadDisabled);
            }
            // 判斷上層路由 讀取功能未選取時 自動選取讀取功能
            if (!this.checkedRead[parentKey]) {
                // 選取主路由的 讀取選項
                this.$set(this.checkedRead, parentKey, true);
                // disabeld 主路由的 讀取選項
                this.$set(this.checkReadDisabled, parentKey, true);
                // 重新設定 disabled keys 值
                disabledKeys = this.getReadDisabledKeys(this.checkReadDisabled);
            }
            // 判斷子路由都沒有選中時 取消主路由讀取選項的 disabled
            if (!this.countDisabledChildrenKeys(disabledKeys, this.childrenKeys[parentKey])) {
                this.$set(this.checkReadDisabled, parentKey, false);
            } else {
                this.$set(this.checkReadDisabled, parentKey, true);
            }

            const readKeys = Object.keys(this.checkedRead).map((objKey) => objKey);
            if (this.countDisabledChildrenKeys(readKeys, this.childrenKeys[parentKey])) {
                this.$set(this.checkReadDisabled, parentKey, true);
            }
        },
    },
    async created() {
        this.id = this.$route.params.id;
        if (this.$route.name === "permission_role_set" && this.$route.params.id !== undefined) {
            // 判斷為 permission_role_set 路由且有網址參數 id 值時 代表為更新狀態
            this.isUpdate = true;
        }
        // 取得所有權線路由
        await this.getPermissionList();
        // 初始化設定值 (可選子功能 與 選擇 crud 權限值)
        await this.setDefault(this.permissionList);
        // 判斷是更新才執行
        if (this.isUpdate) {
            // 取得目前角色資料
            await this.getRoleUserData(this.id);
            // this.setReadDisabled(this.permissionList);
        }
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tree-node__content {
    min-height: 50px;
    height: auto;
}
.custom-tree-node {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    padding-right: 8px;
}

.el-tree-node__expand-icon .el-icon-caret-right {
}
.treeBox {
    .el-tree {
        @apply w-full rounded-lg;
    }
    @apply w-full rounded-lg;
}
</style>
